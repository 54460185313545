import { connect } from 'react-redux'
import MapComponent from '../components/map'
import {
  loadNearbyStops,
  toggleMapStopPreview,
  updateMapCenter,
  updateUserLocation
} from '../actions/actions'
import _isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state) => {
  return {
    coords: _isEmpty(state.mapCenter)
      ? state.userLocation.coords
      : state.mapCenter,
    mapStopPreview: state.mapStopPreview,
    nearbyStops: state.nearbyStops,
    userLocation: state.userLocation
  }
}

const mapDispatchToProps = {
  loadNearbyStops,
  toggleMapStopPreview,
  updateMapCenter,
  updateUserLocation
}

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent)
