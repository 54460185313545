import React from 'react'
import loading from '../assets/images/omg-loading.svg'

const Loading = () => (
  <div className='loading'>
    <img src={loading} alt='loading' className='loading-animation' />
    <div className='loading-text'>Loading</div>
  </div>
)

export default Loading
