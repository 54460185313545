export function arrowDirection(direction) {
  return {
    NB: 'up',
    EB: 'right',
    SB: 'down',
    WB: 'left'
  }[direction]
}

export function googleMapsAPIKey() {
  // You can append `&v=VERSION` to the api key below to explicitly set a specific
  // version or frequency (default is weekly). We specified `&v=3.31` in the past to
  // mitigate bugs with the new map renderer, but that version has since been sunsetted.
  // Google Maps API Versioning: https://developers.google.com/maps/documentation/javascript/versions
  return process.env.REACT_APP_GOOGLE_MAPS_API_KEY
}

export function getDisplayMode() {
  const iosStandalone =
    'standalone' in window.navigator && window.navigator.standalone
  const androidStandalone = window.matchMedia('(display-mode: standalone)')
    .matches
  return iosStandalone || androidStandalone ? 'standalone' : 'browser'
}

export function pluralizer(num, word) {
  switch (num) {
    case 0:
      return `No ${word}s`
    case 1:
      return `${num} ${word}`
    default:
      return `${num} ${word}s`
  }
}
