export const mplsCoords = { latitude: 44.975918, longitude: -93.273079 }

export const regionStopTypeMappings = {
  msp: 'transit',
  niceride: 'bike'
}

export const transitRailStopIds = [
  17874,
  51405,
  51408,
  51409,
  51411,
  51412,
  51413,
  51414,
  51415,
  51416,
  51417,
  51418,
  51419,
  51420,
  51421,
  51422,
  51423,
  51424,
  51426,
  51427,
  51428,
  51429,
  51430,
  51431,
  51432,
  51433,
  51434,
  51435,
  51436,
  51437,
  53279,
  53280,
  53342,
  53343,
  53344,
  53345,
  53346,
  53347,
  53348,
  53349,
  53350,
  53351,
  53352,
  55997,
  55998,
  55999,
  56001,
  56002,
  56003,
  56004,
  56005,
  56006,
  56007,
  56008,
  56009,
  56010,
  56011,
  56012,
  56013,
  56014,
  56015,
  56016,
  56017,
  56018,
  56026,
  56027,
  56028,
  56029,
  56030,
  56031,
  56032,
  56033,
  56034,
  56035,
  56036,
  56037,
  56038,
  56039,
  56040,
  56041,
  56042,
  56043,
  56098,
  56330,
  56332,
  56333,
  56334,
  56335,
  56336,
  56338,
  56339
]

// ARRIVAL FORMATS CHANGED AROUND 11/29/2022
// export const newArrivalFormat =
//   {
//     "actual": true,
//     "trip_id": "22197485-AUG22-MVS-BUS-Weekday-01",
//     "stop_id": 20047,
//     "departure_text": "11 Min",
//     "departure_time": 1669928503,
//     "description": "Lyndale Av / Knox - American",
//     "route_id": "4",
//     "route_short_name": "4",
//     "direction_id": 1,
//     "direction_text": "SB",
//     "terminal": "L",
//     "schedule_relationship": "Scheduled"
//   }

// export const oldArrivalFormat =
//   {
//     Actual: true,
//     BlockNumber: 718,
//     DepartureText: '5 Min',
//     DepartureTime: '/Date(1511232960000-0600)/',
//     Description: 'Green Line to St. Paul-Union Depot',
//     Gate: '2',
//     Route: 'Grn',
//     RouteDirection: 'EASTBOUND',
//     Terminal: '',
//     VehicleHeading: 0,
//     VehicleLatitude: 44.971687,
//     VehicleLongitude: -93.215321
//   }


// For offline use. Not used by app.
export const staticArrivals = [
  {
    actual: true,
    stop_id: 1234,
    trip_id: 718,
    departure_text: '5 Min',
    departure_time: '15112329',
    description: 'Green Line to St. Paul-Union Depot',
    gate: '2',
    route: 'Grn',
    route_direction: 'EASTBOUND',
    terminal: '',
    vehicle_heading: 0,
    vehicle_latitude: 44.971687,
    vehicle_longitude: -93.215321
  },
  {
    actual: true,
    stop_id: 1234,
    trip_id: 720,
    departure_text: '16 Min',
    departure_time: '15112336',
    description: 'Green Line to St. Paul-Union Depot',
    gate: '2',
    route: 'Grn',
    route_direction: 'EASTBOUND',
    terminal: ''
  },
  {
    actual: false,
    stop_id: 1234,
    trip_id: 721,
    departure_text: '9:15',
    departure_time: '15112341',
    description: 'Green Line to St. Paul-Union Depot',
    gate: '2',
    route: 'Grn',
    route_direction: 'EASTBOUND',
    terminal: ''
  },
  {
    actual: false,
    stop_id: 1234,
    trip_id: 723,
    departure_text: '9:25',
    departure_time: '15112347',
    description: 'Green Line to St. Paul-Union Depot',
    gate: '2',
    route: 'Grn',
    route_direction: 'EASTBOUND',
    terminal: '',
    vehicle_heading: 0,
    vehicle_latitude: 0.0,
    vehicle_longitude: 0.0
  },
  {
    actual: false,
    stop_id: 1234,
    trip_id: 725,
    departure_text: '9:35',
    departure_time: '15112353',
    description: 'Green Line to St. Paul-Union Depot',
    gate: '2',
    route: 'Grn',
    route_direction: 'EASTBOUND',
    terminal: ''
  },
  {
    actual: false,
    stop_id: 1234,
    trip_id: 726,
    departure_text: '9:45',
    departure_time: '15112359',
    description: 'Green Line to St. Paul-Union Depot',
    gate: '2',
    route: 'Grn',
    route_direction: 'EASTBOUND',
    terminal: ''
  }
]

export const staticFavorites = [56006, 56027, 15877, 13275, 56038, 51409]

export const staticNearbyStops = [
  15798,
  15877,
  15878,
  15797,
  15799,
  15876,
  56006,
  51409
]
