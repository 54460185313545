import store from '../store'
import transitStops from '../static/transit_stops.json'
import _ from 'lodash'
import { getDistance } from 'geolib'

// switching this to true for some reason doesn't work. API seems to work, but
// the BIKE_STATIONS.SUCCESS action never came back. Investigate further later
const BIKE_SYSTEM_ACTIVE = false

export function allStops() {
  return _.concat(allTransitStops(), allBikeStations())
}

export function allTransitStops() {
  return transitStops.map((stop) => {
    return { ...parseTransitStopInfo(stop) }
  })
}

export function allBikeStations() {
  return BIKE_SYSTEM_ACTIVE ? store.getState().bikeStations.data : []
}

function parseTransitStopInfo(stop) {
  return {
    stop_type: 'transit',
    stop_id: parseInt(stop.stop_id, 10),
    stop_name: stop.stop_name,
    stop_lat: parseFloat(stop.stop_lat),
    stop_lon: parseFloat(stop.stop_lon)
  }
}

export function parseBikeStationInfo(station) {
  return {
    stop_type: 'bike',
    stop_id: parseInt(station.station_id, 10),
    stop_name: station.name,
    stop_lat: parseFloat(station.lat),
    stop_lon: parseFloat(station.lon)
  }
}

export function dedupeArrivals(arrivals) {
  return _.reverse(
    _.uniqBy(_.reverse(arrivals), (a) =>
      [a.trip_id, a.departure_time].join('-')
    )
  )
}

export function calculateNearbyStops(coords, stops) {
  const stopsWithDistance = filterStopsByDistance(coords, stops, 0.01).map(
    (stop) => {
      const distance = getDistance(
        { latitude: coords.latitude, longitude: coords.longitude },
        { latitude: stop.stop_lat, longitude: stop.stop_lon },
        10
      )
      return { distance: distance, ...stop }
    }
  )
  return _.sortBy(stopsWithDistance, 'distance')
}

function filterStopsByDistance(coords, stops, distance) {
  var lat = coords.latitude
  var lon = coords.longitude
  var latMin = lat - distance
  var latMax = lat + distance
  var lonMin = lon - distance
  var lonMax = lon + distance

  return stops.filter(
    (stop) =>
      stop.stop_lat < latMax &&
      stop.stop_lat > latMin &&
      stop.stop_lon < lonMax &&
      stop.stop_lon > lonMin
  )
}

export function convertFavoritesToNewFormat(favorites) {
  return favorites
    .filter((el) => {
      return _.isNumber(el) || _.isObject(el)
    })
    .map((favorite) => {
      // check for transit stop or bike station if old favorite format
      if (_.isNumber(favorite)) {
        if (_.find(allTransitStops(), { stop_id: parseInt(favorite, 10) })) {
          return { stop_type: 'transit', stop_id: favorite }
        }
        if (_.find(allBikeStations(), { stop_id: favorite })) {
          return { stop_type: 'bike', stop_id: favorite }
        }
      }

      // otherwise assume new format
      return favorite
    })
}
