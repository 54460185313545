import { USER_FAVORITES, USER_FAVORITES_CONVERT } from '../actions/action_types'
import _findIndex from 'lodash/findIndex'

export const userFavorites = (state = [], action) => {
  switch (action.type) {
    case USER_FAVORITES.SUCCESS:
      const stop = action.payload
      var favorites = [...state]
      const index = _findIndex(favorites, stop)
      index !== -1 ? favorites.splice(index, 1) : favorites.push(stop)
      return favorites
    case USER_FAVORITES_CONVERT.SUCCESS:
      return action.payload
    default:
      return state
  }
}

// needed for backwards compatibility with old favorites
export const favorites = (state = [], action) => {
  switch (action.type) {
    case USER_FAVORITES_CONVERT.SUCCESS:
      return []
    default:
      return state
  }
}

export default userFavorites
