import React, { useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { googleMapsAPIKey } from '../util/helpers'
import MapStopMarker from './map_stop_marker'
import MapStopPreview from './map_stop_preview'
import { mplsCoords } from '../static/data'
import moment from 'moment'
import _isEqual from 'lodash/isEqual'

const YouAreHereMarker = () => <div id='you-are-here' />
const MapCenterMarker = () => <div id='map-center' />

const Map = (props) => {
  const {
    coords,
    userLocation,
    nearbyStops,
    transitArrivals,
    loadNearbyStops,
    updateUserLocation,
    updateMapCenter,
    mapStopPreview,
    toggleMapStopPreview
  } = props

  const clearStopPreview = () => {
    toggleMapStopPreview(null)
  }

  useEffect(() => {
    // update user location if no coords or if stale
    if (
      !coords ||
      moment().diff(moment(userLocation.timestamp), 'seconds') > 60
    ) {
      updateUserLocation()
    }

    // load stop data if coords done loading without errors
    if (coords && !userLocation.isLoading) {
      loadNearbyStops(coords || mplsCoords)
    }

    return clearStopPreview()
    // eslint-disable-next-line
  }, [])

  const isStopMarkerActive = (stop) => {
    return (
      mapStopPreview &&
      mapStopPreview.stop_type === stop.stop_type &&
      mapStopPreview.stop_id === stop.stop_id
    )
  }

  const shortLatLng = (coords) => {
    return { lat: coords.latitude, lng: coords.longitude }
  }

  const fullLatLng = (coords) => {
    return { latitude: coords.lat, longitude: coords.lng }
  }

  const onMapChange = (params) => {
    let newCoords = fullLatLng(params.center)
    if (!_isEqual(coords, newCoords)) {
      updateMapCenter(newCoords)
      loadNearbyStops(newCoords)
    }
  }

  const handleStopClick = (key, childProps) => {
    if (childProps.stop_id) {
      updateMapCenter(fullLatLng(childProps))
      toggleMapStopPreview({ ...childProps })
    }
  }

  const handleUpdateLocationClick = (e) => {
    e.preventDefault()
    clearStopPreview()

    let newCoords = userLocation.coords || mplsCoords
    updateMapCenter(newCoords)

    // only update user location if more than 5 seconds old
    if (moment().diff(moment(userLocation.timestamp), 'seconds') > 5) {
      updateUserLocation()
    }
  }

  const createMapOptions = (map) => {
    return {
      fullscreenControl: false,
      mapTypeControl: false,
      panControl: false,
      streetViewControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }
      ],
      zoomControl: true,
      zoomControlOptions: { position: map.ControlPosition.LEFT_CENTER },
      clickableIcons: false,
      gestureHandling: 'greedy'
    }
  }

  const initialCoords = shortLatLng(coords || mplsCoords)

  return (
    <div className='map-container'>
      {
        // eslint-disable-next-line
        <a href='' onClick={handleUpdateLocationClick} alt='update location'>
          <div className='map-location-button'>
            <i className='fa fa-crosshairs fa-2x' />
          </div>
        </a>
      }

      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsAPIKey() }}
        center={initialCoords}
        zoom={16}
        options={createMapOptions}
        onChange={onMapChange}
        onChildClick={handleStopClick}
        onClick={clearStopPreview}
      >
        {userLocation.coords && (
          <YouAreHereMarker {...shortLatLng(userLocation.coords)} />
        )}
        <MapCenterMarker {...initialCoords} />
        {nearbyStops.data.map((stop) => (
          <MapStopMarker
            key={`map-stop-${stop.stop_type}-${stop.stop_id}`}
            lat={stop.stop_lat}
            lng={stop.stop_lon}
            clickable={true}
            active={isStopMarkerActive(stop)}
            {...stop}
          />
        ))}
      </GoogleMapReact>
      <MapStopPreview {...mapStopPreview} />
    </div>
  )
}

export default Map
