import { connect } from 'react-redux'
import AppComponent from '../components/app'

const mapStateToProps = (state) => {
  return {
    bikeStations: state.bikeStations,
    userLocation: state.userLocation
  }
}

export default connect(mapStateToProps)(AppComponent)
