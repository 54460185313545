import React from 'react'
import TransitStop from '../containers/transit_stop'
import BikeStop from '../containers/bike_stop'
import { regionStopTypeMappings } from '../static/data'

const Stop = (props) => {
  // parse stop_id and stop_type from URL
  const stop_id = parseInt(props.match.params.id, 10)
  const region = props.match.params.region
  const stop_type = regionStopTypeMappings[region]

  return stop_type === 'bike' ? (
    <BikeStop stop_id={stop_id} stop_type={stop_type} />
  ) : (
    <TransitStop stop_id={stop_id} stop_type={stop_type} />
  )
}

export default Stop
