import { connect } from 'react-redux'
import StatusBarComponent from '../components/status_bar'
import { dismissStatusMessage } from '../actions/actions'

const mapStateToProps = (state) => {
  return { dismissedStatuses: state.dismissedStatuses }
}

const mapDispatchToProps = {
  dismissStatusMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBarComponent)
