import { TRANSIT_ARRIVALS } from '../actions/action_types'
import _reject from 'lodash/reject'

const initialState = {
  isLoading: true,
  data: []
}

export const transitArrivals = (state = initialState, action) => {
  switch (action.type) {
    case TRANSIT_ARRIVALS.START:
      return {
        ...state,
        isLoading: true
      }
    case TRANSIT_ARRIVALS.SUCCESS:
      return {
        ...state,
        // remove any existing elements from array for current stop_id
        data: [
          ..._reject(state.data, { stop_id: action.payload.stop_id }),
          action.payload
        ],
        isLoading: false
      }
    case TRANSIT_ARRIVALS.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default transitArrivals
