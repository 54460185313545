import React from 'react'
import TransitStopPreview from '../containers/transit_stop_preview'
import BikeStopPreview from '../containers/bike_stop_preview'

const StopPreview = (props) => {
  if (props.stop_type && props.stop_id) {
    return props.stop_type === 'bike' ? (
      <BikeStopPreview {...props} />
    ) : (
      <TransitStopPreview {...props} />
    )
  } else {
    return null
  }
}

export default StopPreview
