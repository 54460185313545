import { connect } from 'react-redux'
import TransitStopPreviewComponent from '../components/transit_stop_preview'
import {
  loadStopInfo,
  loadStopArrivals,
  updateFavoriteStops
} from '../actions/actions'
import _some from 'lodash/some'
import _find from 'lodash/find'

const mapStateToProps = (state, ownProps) => {
  const stop_id = ownProps.stop_id
  const stop_type = ownProps.stop_type
  const arrivals = _find(state.transitArrivals.data, { stop_id: stop_id })
  return {
    arrivals: (arrivals && arrivals.data) || [],
    isFavorite: _some(state.userFavorites, {
      stop_type: stop_type,
      stop_id: stop_id
    }),
    ..._find(state.stopInfo.data, { stop_type: stop_type, stop_id: stop_id })
  }
}

const mapDispatchToProps = {
  loadStopInfo,
  loadStopArrivals,
  updateFavoriteStops
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransitStopPreviewComponent)
