import React from 'react'
import matt from '../assets/images/matt_decuir.jpg'
import jason from '../assets/images/jason_kadrmas.jpg'
import richard from '../assets/images/richard_barnes.jpg'
import lizzy from '../assets/images/lizzy_wilkins.jpg'
import stuart from '../assets/images/stuart_wainstock.jpg'
import andrew from '../assets/images/andrew_brynjulson.jpg'

const About = () => {
  return (
    <div className='about main-container content-width'>
      <div className='heading-bar'>
        <div className='heading bottom-border'>About</div>
      </div>
      <div className='static-content'>
        OMG Transit started at a{' '}
        <a href='https://medium.com/@omgtransit/the-omg-transit-story-7b02ceb6ea8b'>
          civic hackathon
        </a>
        , evolved into a real-time transit aggregation service, and is now a
        self-sustaining{' '}
        <a href='https://gitlab.com/omgtransit/omgtransit'>
          open source project
        </a>
        .
        <br />
        <h2>STORY</h2>
        <a href='https://medium.com/@omgtransit/the-omg-transit-story-7b02ceb6ea8b'>
          The OMG Transit Story
        </a>
        <br />
        <a href='https://medium.com/@omgtransit/the-updated-omg-transit-story-2e6a007edd94'>
          The Updated OMG Transit Story
        </a>
        <br />
        <br />
        <div className='team'>
          <h2>TEAM</h2>
          <div>
            <img src={matt} alt='Matt Decuir' />
            <br />
            Matt Decuir
            <br />
            Developer
          </div>
          <div>
            <img src={jason} alt='Jason Kadrmas' />
            <br />
            Jason Kadrmas
            <br />
            Developer
          </div>

          <div>
            <img src={richard} alt='Richard Barnes' />
            <br />
            Richard Barnes
            <br />
            Developer
          </div>
          <div>
            <img src={lizzy} alt='Lizzy Wilkins' />
            <br />
            Lizzy Wilkins
            <br />
            Analytics
          </div>

          <div>
            <img src={stuart} alt='Stuart Wainstock' />
            <br />
            Stuart Wainstock
            <br />
            Designer
          </div>
          <div>
            <img src={andrew} alt='Andrew Brynjulson' />
            <br />
            Andrew Brynjulson
            <br />
            Designer
          </div>
        </div>
        <br />
        <div>
          <h2>AWARDS</h2>
          <ul className='awards'>
            <li>White House Champions of Change Commendation</li>
            <li>Intel Labs Data Serviced Accelerator Participant</li>
            <li>ESRI National Day of Civic Hacking Innovation Award</li>
            <li>Beta.MN 2014 Start-up Competition Winner</li>
            <li>3rd place in the Minnesota Cup High Tech division</li>
          </ul>
          <br />
        </div>
      </div>
    </div>
  )
}

export default About
