import { combineReducers } from 'redux'
import { stopInfo } from './stop_info'
import { transitArrivals } from './transit_arrivals'
import { userLocation } from './user_location'
import { userFavorites, favorites } from './user_favorites'
import { nearbyStops } from './nearby_stops'
import { mapCenter } from './map_center'
import { mapStopPreview } from './map_stop_preview'
import { dismissedStatuses } from './dismissed_statuses'
import { bikeStations } from './bike_stations'
import { bikeAvailability } from './bike_availability'

const rootReducer = combineReducers({
  stopInfo,
  transitArrivals,
  userLocation,
  userFavorites,
  favorites,
  nearbyStops,
  mapCenter,
  mapStopPreview,
  dismissedStatuses,
  bikeStations,
  bikeAvailability
})

export default rootReducer
