import React, { useEffect } from 'react'
import StopPreview from '../components/stop_preview'
import Legend from './legend'
import { mplsCoords } from '../static/data'
import moment from 'moment'

const List = (props) => {
  const {
    coords,
    userLocation,
    nearbyStops,
    transitArrivals,
    loadNearbyStops,
    updateUserLocation
  } = props

  useEffect(() => {
    // update user location if no coords or if stale
    if (
      !coords ||
      moment().diff(moment(userLocation.timestamp), 'seconds') > 60
    ) {
      updateUserLocation()
    }

    // load stop data if coords done loading without errors
    if (coords && !userLocation.isLoading) {
      loadNearbyStops(coords)
    }

    // eslint-disable-next-line
  }, [])

  // reload nearby stops whenever location changes
  useEffect(() => {
    if (coords) {
      loadNearbyStops(coords)
    }
  }, [coords])

  const handleDTMplsClick = (e) => {
    e.preventDefault()
    props.updateMapCenter(mplsCoords)
  }

  let content

  // userLocation state/error handling
  if (userLocation.isLoading) {
    content = (
      <div className='message'>
        <h2>Loading your location...</h2>
      </div>
    )
  } else {
    if (userLocation.error && !coords) {
      content = (
        <div className='message'>
          <span className='heading'>Doh!</span>
          <br />
          OMG Transit relies on your geolocation to show nearby transit options.
          <br />
          <br />
          Please allow location access to get the most out of our app!
          <br />
          <br />
          {
            // eslint-disable-next-line
            <a href='' onClick={handleDTMplsClick}>
              Click here
            </a>
          }{' '}
          to go straight to Downtown Minneapolis instead.
        </div>
      )
    }
  }

  // nearbyStops loading
  if (
    nearbyStops.isLoading &&
    !userLocation.isLoading &&
    !userLocation.error &&
    !coords
  ) {
    content = (
      <div className='message'>
        <h2>Loading nearby stops...</h2>
      </div>
    )
  }

  // done loading, no error OR coords present
  if (
    !userLocation.isLoading &&
    !nearbyStops.isLoading &&
    (!userLocation.error || coords)
  ) {
    if (nearbyStops.data.length === 0) {
      content = (
        <div className='message'>
          <span className='heading'>Zoinks!</span>
          <br />
          We couldn&#39;t find any stops near your current location.
          <br />
          <br />
          {
            // eslint-disable-next-line
            <a href='' onClick={handleDTMplsClick}>
              Click here
            </a>
          }{' '}
          to see Downtown Minneapolis instead.
          <br />
          <br />
          (This app currently only supports Minneapolis/St Paul.)
        </div>
      )
    } else if (transitArrivals.error) {
      content = (
        <div className='message'>
          <span className='heading'>Network error!</span>
          <br />
          Something prevented us from loading real-time data for your nearby
          stops.
          <br />
          <br />
          If you have an ad blocker like Privacy Badger enabled, please disable
          it for this page.
        </div>
      )
    } else {
      content = (
        <div className='stop-list'>
          {nearbyStops.data
            .map((stop) => (
              <StopPreview
                key={`list-stop-${stop.stop_type}-${stop.stop_id}`}
                hideNoData={true}
                {...stop}
              />
            ))
            .slice(0, 10)}
          <Legend />
        </div>
      )
    }
  }

  return <div className='list main-container content-width'>{content}</div>
}

export default List
