import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer id='footer'>
      <div className='footer-container'>
        <NavLink to='/list'>
          <i className='fa fa-list' />
          <span className='text'>List</span>
        </NavLink>
        <NavLink to='/map'>
          <i className='icon-omg-map' />
          <span className='text'>Map</span>
        </NavLink>
        <NavLink to='/favorites'>
          <i className='fa fa-star' />
          <span className='text'>Favorites</span>
        </NavLink>
      </div>
    </footer>
  )
}

export default Footer
