import axios from 'axios'
import * as types from './action_types'
import _ from 'lodash'
import {
  allStops,
  allBikeStations,
  allTransitStops,
  calculateNearbyStops,
  convertFavoritesToNewFormat,
  dedupeArrivals
} from './action_helpers'

export function loadStopArrivals(stopId) {
  const url = `https://svc.metrotransit.org/NexTrip/${stopId}?format=json`
  return function (dispatch) {
    dispatch({ type: types.TRANSIT_ARRIVALS.START })
    axios
      .get(url)
      .then((response) => {
        var arrivals = dedupeArrivals(response?.data?.departures || [])
        dispatch({
          type: types.TRANSIT_ARRIVALS.SUCCESS,
          payload: { stop_id: stopId, data: arrivals }
        })
      })
      .catch((error) => {
        dispatch({
          type: types.TRANSIT_ARRIVALS.FAILURE,
          payload: error
        })
      })
  }
}

export function loadStopInfo(stop_type, stop_id) {
  return function (dispatch, getState) {
    dispatch({ type: types.STOP_INFO.START })
    const stops = stop_type === 'bike' ? allBikeStations() : allTransitStops()
    const stopInfo = _.find(stops, { stop_id: parseInt(stop_id, 10) })
    if (stopInfo) {
      dispatch({
        type: types.STOP_INFO.SUCCESS,
        payload: stopInfo
      })
    } else {
      dispatch({
        type: types.STOP_INFO.FAILURE,
        payload: 'Stop info not found.'
      })
    }
  }
}

export function updateUserLocation() {
  return function (dispatch) {
    dispatch({ type: types.USER_LOCATION.START })
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch({
          type: types.USER_LOCATION.SUCCESS,
          payload: position
        })
        dispatch({
          type: types.MAP_CENTER.SUCCESS,
          payload: {}
        })
      },
      (error) => {
        dispatch({
          type: types.USER_LOCATION.FAILURE,
          payload: error
        })
      }
    )
  }
}

export function loadNearbyStops(coords) {
  return function (dispatch, getState) {
    dispatch({ type: types.NEARBY_STOPS.START })
    const nearbyStops = calculateNearbyStops(coords, allStops()).slice(0, 20)
    dispatch({
      type: types.NEARBY_STOPS.SUCCESS,
      payload: nearbyStops
    })
  }
}

export function updateMapCenter(coords) {
  return function (dispatch) {
    dispatch({ type: types.MAP_CENTER.START })
    dispatch({
      type: types.MAP_CENTER.SUCCESS,
      payload: coords
    })
  }
}

export function updateFavoriteStops(stop) {
  return function (dispatch) {
    dispatch({ type: types.USER_FAVORITES.START })
    dispatch({
      type: types.USER_FAVORITES.SUCCESS,
      payload: stop
    })
  }
}

export function toggleMapStopPreview(stop) {
  return function (dispatch) {
    dispatch({ type: types.MAP_STOP_PREVIEW.START })
    dispatch({
      type: types.MAP_STOP_PREVIEW.SUCCESS,
      payload: stop
    })
  }
}

export function dismissStatusMessage(messageId) {
  return function (dispatch) {
    dispatch({ type: types.DISMISS_STATUS_MESSAGE.START })
    dispatch({
      type: types.DISMISS_STATUS_MESSAGE.SUCCESS,
      payload: messageId
    })
  }
}

export function loadBikeStations() {
  const url = 'https://gbfs.niceridemn.com/gbfs/en/station_information.json'
  return function (dispatch) {
    dispatch({ type: types.BIKE_STATIONS.START })
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.BIKE_STATIONS.SUCCESS,
          payload: response
        })
      })
      .catch((error) => {
        dispatch({
          type: types.BIKE_STATIONS.FAILURE,
          payload: error
        })
      })
  }
}

export function loadBikeAvailability() {
  const url = 'https://gbfs.niceridemn.com/gbfs/en/station_status.json'
  return function (dispatch) {
    dispatch({ type: types.BIKE_AVAILABILITY.START })
    axios
      .get(url)
      .then((response) => {
        dispatch({
          type: types.BIKE_AVAILABILITY.SUCCESS,
          payload: response
        })
      })
      .catch((error) => {
        dispatch({
          type: types.BIKE_AVAILABILITY.FAILURE,
          payload: error
        })
      })
  }
}

export function convertFavorites(currentFavorites) {
  return function (dispatch) {
    dispatch({ type: types.USER_FAVORITES_CONVERT.START })
    var newFavorites = convertFavoritesToNewFormat(currentFavorites)
    dispatch({
      type: types.USER_FAVORITES_CONVERT.SUCCESS,
      payload: newFavorites
    })
  }
}
