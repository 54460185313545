import React, { useEffect } from 'react'
import StopPreview from '../components/stop_preview'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'

const Favorites = ({ userFavorites, oldFavorites, convertFavorites }) => {
  // only convert oldFavorites if present
  useEffect(() => {
    _some(oldFavorites, Number) && convertFavorites(oldFavorites)
  }, [])

  let content

  if (_isEmpty(userFavorites)) {
    content = (
      <div className='message'>
        {"You don't have any favorites yet!"}
        <br />
        {"Try clicking on a stop's "}
        <i className='fa fa-star' /> {' to add it to your favorites.'}
      </div>
    )
  } else {
    content = userFavorites.map((stop) => {
      if (stop && stop.stop_type && stop.stop_id) {
        return (
          <StopPreview
            key={`${stop.stop_type}-${stop.stop_id}`}
            {...stop}
            hideNoData={false}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <div className='favorites main-container content-width'>
      <div className='heading-bar'>
        <div className='heading bottom-border'>Favorites</div>
      </div>
      {content}
    </div>
  )
}

export default Favorites
