import { STOP_INFO } from '../actions/action_types.js'

const initialState = {
  isLoading: true,
  data: []
}

export const stopInfo = (state = initialState, action) => {
  switch (action.type) {
    case STOP_INFO.SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        isLoading: false
      }
    case STOP_INFO.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default stopInfo
