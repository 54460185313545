import React, { Component } from 'react'
import MapPreview from './map_preview'
import { pluralizer } from '../util/helpers'
import _isEmpty from 'lodash/isEmpty'

const StopBike = (props) => {
  const {
    stop_id,
    stop_type,
    stop_name,
    availability,
    isFavorite,
    loadStopInfo,
    loadBikeAvailability,
    updateFavoriteStops
  } = props

  useEffect(() => {
    !stop_name && loadStopInfo('bike', stop_id)
    loadBikeAvailability()

    // reload every 30 seconds
    const interval = setInterval(() => {
      loadBikeAvailability()
    }, 30 * 1000)
    return clearInterval(interval)
  }, [])

  const handleFavoriteClick = (e) => {
    e.preventDefault()
    updateFavoriteStops({
      stop_type: stop_type,
      stop_id: stop_id
    })
  }

  let content
  if (_isEmpty(availability)) {
    content = (
      <div className='availability-bar pnd'>
        <div className='availability-info'>No Data</div>
      </div>
    )
  } else {
    content = (
      <div>
        <div className='availability-bar pnr real-time'>
          <div className='availability-info'>
            {pluralizer(availability.num_bikes_available || 0, 'Bike') +
              ' Available'}
          </div>
        </div>
        <div className='availability-bar p20 real-time'>
          <div className='availability-info'>
            {pluralizer(availability.num_docks_available || 0, 'Dock') +
              ' Available'}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='stop main-container content-width'>
      <div className='stop-map-preview'>
        <MapPreview {...props} />
      </div>
      <div className='heading-bar'>
        <div className='stop-heading'>{stop_name}</div>
        <div className='favorite'>
          {
            // eslint-disable-next-line
            <a
              href=''
              className={`favorite ${isFavorite && 'gold'}`}
              onClick={handleFavoriteClick}
            >
              <i className='fa fa-star fa-lg' />
            </a>
          }
        </div>
      </div>
      <div className='availability'>{content}</div>
      <div className='legend'>
        For more information about how Nice Ride bike share works,
        <br />
        please visit{' '}
        <a
          href='https://www.niceridemn.org/'
          target='_blank'
          rel='noopener noreferrer'
        >
          niceridemn.org
        </a>
      </div>
    </div>
  )
}

export default StopBike
