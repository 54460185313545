import { connect } from 'react-redux'
import ListComponent from '../components/list'
import {
  loadNearbyStops,
  updateMapCenter,
  updateUserLocation
} from '../actions/actions'
import _isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state) => {
  return {
    coords: _isEmpty(state.mapCenter)
      ? state.userLocation.coords
      : state.mapCenter,
    nearbyStops: state.nearbyStops,
    transitArrivals: state.transitArrivals,
    userLocation: state.userLocation
  }
}

const mapDispatchToProps = {
  loadNearbyStops,
  updateMapCenter,
  updateUserLocation
}

export default connect(mapStateToProps, mapDispatchToProps)(ListComponent)
