import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createHashHistory } from 'history'
import rootReducer from './reducers'
import { loadFromLocalStorage, saveToLocalStorage } from './util/local_storage'
import { initializeTracking, trackEvents } from './util/analytics'
import { initializeErrorTracking } from './util/errors'
import _throttle from 'lodash/throttle'

export const history = createHashHistory()
initializeTracking(history)
initializeErrorTracking(history)

// use state from local storage if present
const initialState = loadFromLocalStorage('state') || {}

const middleware = applyMiddleware(thunk, trackEvents)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(middleware)
)

// save state & settings to local storage
store.subscribe(
  _throttle(() => {
    saveToLocalStorage('state', {
      userLocation: store.getState().userLocation,
      userFavorites: store.getState().userFavorites,
      dismissedStatuses: store.getState().dismissedStatuses,
      bikeStations: store.getState().bikeStations
    })
    saveToLocalStorage('settings', {
      lastLocation: history.location.pathname
    })
  }, 1000)
)

export default store
