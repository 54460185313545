import { NEARBY_STOPS } from '../actions/action_types'

const initialState = {
  isLoading: true,
  data: []
}

export const nearbyStops = (state = initialState, action) => {
  switch (action.type) {
    case NEARBY_STOPS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      }
    case NEARBY_STOPS.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default nearbyStops
