import { connect } from 'react-redux'
import FavoritesComponent from '../components/favorites'
import { convertFavorites } from '../actions/actions'

const mapStateToProps = (state) => {
  return {
    userFavorites: state.userFavorites,
    oldFavorites: state.favorites
  }
}

const mapDispatchToProps = {
  convertFavorites
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesComponent)
