import { USER_LOCATION } from '../actions/action_types'

const initialState = {
  isLoading: true,
  error: null
}

export const userLocation = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOCATION.SUCCESS:
      return {
        coords: {
          latitude: action.payload.coords.latitude,
          longitude: action.payload.coords.longitude
        },
        timestamp: action.payload.timestamp,
        isLoading: false
      }
    case USER_LOCATION.FAILURE:
      return {
        error: {
          code: action.payload.code,
          message: action.payload.message
        },
        isLoading: false
      }
    default:
      return state
  }
}

export default userLocation
