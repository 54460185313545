import ReactGA from 'react-ga'
import { getDisplayMode } from './helpers'

export const initializeTracking = (history) => {
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' }
    })

    const displayMode = getDisplayMode()
    ReactGA.set({ dimension1: displayMode })
    ReactGA.pageview(window.location.pathname + window.location.search)

    history.listen((location) => {
      const path = location.pathname + location.search
      ReactGA.set({ page: path })
      ReactGA.set({ dimension1: displayMode })
      ReactGA.pageview(path)
    })
  } else {
    console.log('Attempted to initialize analytics')
  }
}

export const trackEvents = (store) => (next) => (action) => {
  if (action.type.match(/SUCCESS|FAILURE/)) {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.event({ category: 'Redux Action', action: action.type })
    }
  }
  return next(action)
}
