import React from 'react'

const MobileApps = (props) => {
  return (
    <div className='mobile-apps main-container content-width'>
      <div className='heading-bar'>
        <div className='heading bottom-border'>Mobile Apps</div>
      </div>
      <br />
      <div className='static-content'>
        OMG Transit is no longer in the app store, but you can still get the
        same mobile app experience by adding it to your home screen.
        <h2>Add to Home Screen Instructions</h2>
        <div className='bold'>iOS</div>
        <ol className='centered-list'>
          <li>
            Open <span className='bold'>Safari</span>
          </li>
          <li>
            Visit <a href='https://omgtransit.com'>omgtransit.com</a>
          </li>
          <li>Click Share, then Add to Home Screen</li>
          <li>Done!</li>
        </ol>
        <br />
        <br />
        <div className='bold'>Android</div>
        <ol className='centered-list'>
          <li>
            Open <span className='bold'>Chrome</span>
          </li>
          <li>
            Visit <a href='https://omgtransit.com'>omgtransit.com</a>
          </li>
          <li>Click Menu, then Add to Home Screen</li>
          <li>Done!</li>
        </ol>
      </div>
    </div>
  )
}

export default MobileApps
