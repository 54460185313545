import { DISMISS_STATUS_MESSAGE } from '../actions/action_types'

export const dismissedStatuses = (state = [], action) => {
  switch (action.type) {
    case DISMISS_STATUS_MESSAGE.SUCCESS:
      return [...state, action.payload]
    default:
      return state
  }
}

export default dismissedStatuses
