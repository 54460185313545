import React from 'react'
import moment from 'moment'
import { arrowDirection } from '../util/helpers'

const ArrivalChip = (props) => {
  let arrowClass = `icon-omg-arrow-${arrowDirection(props.route_direction)}`
  let arrivalTime = moment.unix(props.departure_time)
  let minutesAway = arrivalTime.diff(moment(), 'minutes')
  let arrivalText = arrivalTime.format('h:mm')
  let chipClass = 'p20'
  let realTime = minutesAway < 20

  if (realTime) {
    arrivalText = `${minutesAway} Min`
  }
  if (minutesAway <= 10) {
    chipClass = 'p10'
  }
  if (minutesAway <= 5) {
    chipClass = 'p5'
  }
  if (minutesAway < 1) {
    arrivalText = 'Now'
  }

  let realTimeClass = realTime && props.actual ? 'real-time' : 'scheduled'

  return (
    <span className={`arrival-chip ${chipClass} ${realTimeClass}`}>
      <i className={arrowClass} /> {props.route_short_name}
      {props.terminal}
      {realTime && ` • ${arrivalText}`}
    </span>
  )
}

export default ArrivalChip
