import { BIKE_STATIONS } from '../actions/action_types'
import { parseBikeStationInfo } from '../actions/action_helpers'

const initialState = {
  isLoading: true,
  data: []
}

export const bikeStations = (state = initialState, action) => {
  switch (action.type) {
    case BIKE_STATIONS.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data.stations.map((station) => {
          return parseBikeStationInfo(station)
        }),
        timestamp: action.payload.data.last_updated,
        isLoading: false
      }
    case BIKE_STATIONS.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default bikeStations
