import React from 'react'
import { transitRailStopIds } from '../static/data'
import _includes from 'lodash/includes'

const MapStopMarker = (props) => {
  let markerType = props.stop_type

  if (props.stop_type === 'transit') {
    markerType = _includes(transitRailStopIds, props.stop_id) ? 'train' : 'bus'
  }

  const clickable = props.clickable && 'clickable'
  const active = props.active && 'active'
  return <div className={`map-pin ${markerType} ${clickable} ${active}`} />
}

export default MapStopMarker
