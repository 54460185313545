import { BIKE_AVAILABILITY } from '../actions/action_types'

const initialState = {
  isLoading: true,
  data: []
}

export const bikeAvailability = (state = initialState, action) => {
  switch (action.type) {
    case BIKE_AVAILABILITY.SUCCESS:
      return {
        ...state,
        data: action.payload.data.data.stations,
        timestamp: action.payload.data.last_updated,
        isLoading: false
      }
    case BIKE_AVAILABILITY.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default bikeAvailability
