import { connect } from 'react-redux'
import BikeStopComponent from '../components/bike_stop'
import {
  loadStopInfo,
  loadBikeAvailability,
  updateFavoriteStops
} from '../actions/actions'
import _some from 'lodash/some'
import _find from 'lodash/find'

const mapStateToProps = (state, ownProps) => {
  const stop_id = ownProps.stop_id
  const stop_type = ownProps.stop_type
  return {
    availability:
      _find(state.bikeAvailability.data, { station_id: stop_id.toString() }) ||
      {},
    isFavorite: _some(state.userFavorites, {
      stop_type: stop_type,
      stop_id: stop_id
    }),
    ..._find(state.bikeStations.data, {
      stop_type: stop_type,
      stop_id: stop_id
    })
  }
}

const mapDispatchToProps = {
  loadStopInfo,
  loadBikeAvailability,
  updateFavoriteStops
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeStopComponent)
