import React from 'react'
import { NavLink } from 'react-router-dom'
import { history } from '../store'
import headerLogo from '../assets/images/omg-header-words.svg'

const Header = (props) => {
  const handleBackButtonClick = (e) => {
    e.preventDefault()
    history.goBack()
  }

  return (
    <header className='content-width'>
      <div className='header-container'>
        {
          // eslint-disable-next-line
          <a href='' onClick={handleBackButtonClick} alt='previous page'>
            <i className='fa fa-chevron-left fa-2x back-button' />
          </a>
        }
        <img src={headerLogo} alt='omg-transit' className='header-logo' />
        <NavLink to='/more'>
          <i className='fa fa-ellipsis-v fa-2x more-menu' />
        </NavLink>
      </div>
    </header>
  )
}

export default Header
