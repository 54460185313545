import React from 'react'

const Legend = () => (
  <div className='legend'>
    <span className='real-time'>Real-time</span>
    &nbsp;•&nbsp;
    <span className='scheduled'>Scheduled</span>
    <br />
    <div className='stop-arrival-chips'>
      <span className='arrival-chip p5 real-time'>0-5 Min</span>
      <span className='arrival-chip p10 real-time'>6-10 Min</span>
      <span className='arrival-chip p20 real-time'>11+ Min</span>
    </div>
    <div className='description-text top-padded'>
      JavaScript Error Monitoring by{' '}
      <a href='https://trackjs.com/' target='_blank' rel='noopener noreferrer'>
        TrackJS
      </a>
      .
    </div>
  </div>
)

export default Legend
