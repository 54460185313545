import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route, Redirect, Switch } from 'react-router'
import store, { history } from './store'
import { unregister } from './util/register_service_worker'
import { loadFromLocalStorage } from './util/local_storage'

import App from './containers/app'
import List from './containers/list'
import Map from './containers/map'
import Favorites from './containers/favorites'
import Stop from './components/stop'
import More from './components/more'
import MobileApps from './components/mobile_apps'
import About from './components/about'

// retrieve last location from local storage
let settings = loadFromLocalStorage('settings')
let lastLocation = settings?.lastLocation || '/list'

// redirect any route without a match to the last location
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App>
        <Switch>
          <Route path='/list' component={List} />
          <Route path='/map' component={Map} />
          <Route path='/favorites' component={Favorites} />
          <Route path='/stop/:region/:id' component={Stop} />
          <Route path='/more' component={More} />
          <Route path='/apps' component={MobileApps} />
          <Route path='/about' component={About} />
          <Redirect path='*' to={lastLocation} />
        </Switch>
      </App>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// no longer caching the app with registerServiceWorker
unregister()
