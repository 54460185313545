import React, { useEffect } from 'react'
import statusMessage from '../static/status_message.json'
import { getDisplayMode } from '../util/helpers'
import _includes from 'lodash/includes'
import moment from 'moment'

const StatusBar = ({ dismissedStatuses, dismissStatusMessage }) => {
  useEffect(() => {
    let timeout
    // automatically dismiss after 15 seconds, if visible
    if (showStatusBar()) {
      timeout = setTimeout(() => {
        dismissStatusMessage(statusMessage.id)
      }, 15 * 1000)
    }
    return clearInterval(timeout)
  }, [])

  const handleDismissClick = (e) => {
    e.preventDefault()
    dismissStatusMessage(statusMessage.id)
  }

  const showStatusBar = () => {
    const messageExpired = moment(statusMessage.expiration).isBefore(moment())
    const messageDismissed = _includes(dismissedStatuses, statusMessage.id)
    const correctDisplayMode = _includes(
      statusMessage.displayModes,
      getDisplayMode()
    )
    return !messageExpired && !messageDismissed && correctDisplayMode
  }

  var messageContent = null

  if (showStatusBar()) {
    messageContent = (
      <div className='status-container'>
        <div className='status-bar content-width'>
          <div
            className='status-message'
            dangerouslySetInnerHTML={{ __html: statusMessage.messageHtml }}
          />
          {
            // eslint-disable-next-line
            <a href='' onClick={handleDismissClick} alt='dismiss'>
              <div className='status-close'>
                <i className='fa fa-close' />
              </div>
            </a>
          }
        </div>
      </div>
    )
  }
  return messageContent
}

export default StatusBar
