import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { pluralizer } from '../util/helpers'

const BikeStopPreview = (props) => {
  const {
    stop_id,
    stop_type,
    stop_name,
    availability,
    isFavorite,
    loadStopInfo,
    loadBikeAvailability,
    updateFavoriteStops
  } = props

  useEffect(() => {
    !stop_name && loadStopInfo('bike', stop_id)
    loadBikeAvailability()

    // reload every 30 seconds
    const interval = setInterval(() => {
      loadBikeAvailability()
    }, 30 * 1000)

    return clearInterval(interval)
  }, [])

  const handleFavoriteClick = (e) => {
    e.preventDefault()
    updateFavoriteStops({
      stop_type: stop_type,
      stop_id: stop_id
    })
  }

  return (
    <div className='stop-preview'>
      <div className='stop-info'>
        <Link to={`/stop/niceride/${stop_id}`}>
          <div className='stop-title'>{stop_name}</div>
          <div className='stop-arrival-chips'>
            <span className='arrival-chip pnr real-time'>
              {pluralizer(availability.num_bikes_available || 0, 'Bike')}
            </span>
            <span className='arrival-chip p20 real-time'>
              {pluralizer(availability.num_docks_available || 0, 'Dock')}
            </span>
          </div>
        </Link>
      </div>
      <div className='favorite'>
        {
          // eslint-disable-next-line
          <a
            href=''
            className={`favorite ${isFavorite && 'gold'}`}
            onClick={handleFavoriteClick}
          >
            <i className='fa fa-star' />
          </a>
        }
      </div>
    </div>
  )
}

export default BikeStopPreview
