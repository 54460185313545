import React, { useEffect } from 'react'
import Header from '../components/header'
import StatusBar from '../containers/status_bar'
import Footer from './footer'
import { updateUserLocation, loadBikeStations } from '../actions/actions'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import '../assets/styles/main.css'

const App = (props) => {
  useEffect(() => {
    // update user location if stale
    if (
      _isEmpty(props?.userLocation?.coords) ||
      moment().diff(moment(props?.userLocation.timestamp), 'minutes') > 10
    ) {
      updateUserLocation()
    }

    // update bike stations if stale
    if (
      _isEmpty(props?.bikeStations?.data) ||
      moment().diff(moment.unix(props?.bikeStations?.timestamp), 'hours') > 12
    ) {
      loadBikeStations()
    }
  }, [])

  return (
    <div>
      <Header />
      <main>
        <StatusBar />
        {props.children}
      </main>
      <Footer />
    </div>
  )
}

export default App
