import React from 'react'
import moment from 'moment'
import { arrowDirection } from '../util/helpers'

const ArrivalBar = (props) => {
  let arrowClass = `icon-omg-arrow-${arrowDirection(props.route_direction)}`
  let arrivalTime = moment.unix(props.departure_time)
  let minutesAway = arrivalTime.diff(moment(), 'minutes')
  let arrivalText = arrivalTime.format('h:mm')
  let chipClass = 'p20'
  let realTime = minutesAway < 20

  if (realTime) {
    arrivalText = `${minutesAway} Min`
  }
  if (minutesAway <= 10) {
    chipClass = 'p10'
  }
  if (minutesAway <= 5) {
    chipClass = 'p5'
  }
  if (minutesAway < 1) {
    arrivalText = 'Now'
  }

  let realTimeClass = realTime && props.actual ? 'real-time' : 'scheduled'
  let realTimeUnavailable = realTime && !props.actual && '~'
  let descriptionText = realTime && arrivalTime.format('h:mm a')

  return (
    <div className={`arrival-bar ${chipClass} ${realTimeClass}`}>
      <div className='arrival-info'>
        <i className={arrowClass} />
        &nbsp;{props.route_short_name}
        {props.terminal}
        <br />
        <div className='description-text'>{props.description}</div>
      </div>
      <div className={`arrival-time`}>
        {arrivalText}
        <br />
        <span className='description-text'>
          {realTimeUnavailable}
          {descriptionText}
        </span>
      </div>
    </div>
  )
}

export default ArrivalBar
