import { TrackJS } from 'trackjs'

export const initializeErrorTracking = (history) => {
  if (process.env.REACT_APP_TRACKJS_TOKEN) {
    // initialize trackJS
    TrackJS.install({
      token: process.env.REACT_APP_TRACKJS_TOKEN,
      application: 'omg-transt'
    })
  } else {
    console.log('Attempted to initialize error tracking')
  }
}
