export const loadFromLocalStorage = (title) => {
  try {
    const serializedState = localStorage.getItem(title)
    return serializedState === null ? undefined : JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveToLocalStorage = (title, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(title, serializedState)
  } catch (err) {
    console.log(err)
  }
}
