export const STOP_INFO = {
  START: 'STOP_INFO_LOAD_START',
  SUCCESS: 'STOP_INFO_LOAD_SUCCESS',
  FAILURE: 'STOP_INFO_LOAD_FAILURE'
}

export const TRANSIT_ARRIVALS = {
  START: 'TRANSIT_ARRIVALS_LOAD_START',
  SUCCESS: 'TRANSIT_ARRIVALS_LOAD_SUCCESS',
  FAILURE: 'TRANSIT_ARRIVALS_LOAD_FAILURE'
}

export const USER_LOCATION = {
  START: 'USER_LOCATION_UPDATE_START',
  SUCCESS: 'USER_LOCATION_UPDATE_SUCCESS',
  FAILURE: 'USER_LOCATION_UPDATE_FAILURE'
}

export const USER_FAVORITES = {
  START: 'USER_FAVORITE_TOGGLE_START',
  SUCCESS: 'USER_FAVORITE_TOGGLE_SUCCESS',
  FAILURE: 'USER_FAVORITE_TOGGLE_FAILURE'
}

export const USER_FAVORITES_CONVERT = {
  START: 'USER_FAVORITES_CONVERT_START',
  SUCCESS: 'USER_FAVORITES_CONVERT_SUCCESS',
  FAILURE: 'USER_FAVORITES_CONVERT_FAILURE'
}

export const NEARBY_STOPS = {
  START: 'NEARBY_STOPS_LOAD_START',
  SUCCESS: 'NEARBY_STOPS_LOAD_SUCCESS',
  FAILURE: 'NEARBY_STOPS_LOAD_FAILURE'
}

export const MAP_CENTER = {
  START: 'MAP_CENTER_UPDATE_START',
  SUCCESS: 'MAP_CENTER_UPDATE_SUCCESS',
  FAILURE: 'MAP_CENTER_UPDATE_FAILURE'
}

export const MAP_STOP_PREVIEW = {
  START: 'MAP_STOP_PREVIEW_TOGGLE_START',
  SUCCESS: 'MAP_STOP_PREVIEW_TOGGLE_SUCCESS',
  FAILURE: 'MAP_STOP_PREVIEW_TOGGLE_FAILURE'
}

export const DISMISS_STATUS_MESSAGE = {
  START: 'DISMISS_STATUS_MESSAGE_START',
  SUCCESS: 'DISMISS_STATUS_MESSAGE_SUCCESS',
  FAILURE: 'DISMISS_STATUS_MESSAGE_FAILURE'
}

export const BIKE_STATIONS = {
  START: 'BIKE_STATIONS_LOAD_START',
  SUCCESS: 'BIKE_STATIONS_LOAD_SUCCESS',
  FAILURE: 'BIKE_STATIONS_LOAD_FAILURE'
}

export const BIKE_AVAILABILITY = {
  START: 'BIKE_AVAILABILITY_LOAD_START',
  SUCCESS: 'BIKE_AVAILABILITY_LOAD_SUCCESS',
  FAILURE: 'BIKE_AVAILABILITY_LOAD_FAILURE'
}
