import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ArrivalChip from './arrival_chip'

const TransitStopPreview = (props) => {
  const {
    stop_id,
    stop_type,
    stop_name,
    arrivals,
    hideNoData,
    isFavorite,
    loadStopInfo,
    loadStopArrivals,
    updateFavoriteStops
  } = props

  useEffect(() => {
    if (!stop_name) {
      loadStopInfo('transit', stop_id)
    }
    loadStopArrivals(stop_id)

    // reload every 30 seconds
    const interval = setInterval(() => {
      loadStopArrivals(stop_id)
    }, 30 * 1000)

    return clearInterval(interval)
  }, [])

  const handleFavoriteClick = (e) => {
    e.preventDefault()
    updateFavoriteStops({
      stop_type: stop_type,
      stop_id: stop_id
    })
  }

  const arrivalData = arrivals?.length > 0

  if (!arrivalData && hideNoData) {
    return null
  }

  let arrivalContent
  if (arrivalData) {
    arrivalContent = arrivals
      .slice(0, 4)
      .map((arrival) => (
        <ArrivalChip
          key={`${stop_id}-${arrival.trip_id}-${arrival.departure_time}`}
          {...arrival}
        />
      ))
  } else {
    arrivalContent = <span className={`arrival-chip pnd`}>No Data</span>
  }

  return (
    <div className='stop-preview'>
      <div className='stop-info'>
        <Link to={`/stop/msp/${stop_id}`}>
          <div className='stop-title'>{stop_name}</div>
          <div className='stop-arrival-chips'>{arrivalContent}</div>
        </Link>
      </div>
      <div className='favorite'>
        {
          // eslint-disable-next-line
          <a
            href=''
            className={`favorite ${isFavorite && 'gold'}`}
            onClick={handleFavoriteClick}
          >
            <i className='fa fa-star' />
          </a>
        }
      </div>
    </div>
  )
}

export default TransitStopPreview
