import React, { useEffect, useState } from 'react'
import MapPreview from './map_preview'
import ArrivalBar from './arrival_bar'
import Legend from './legend'
import Loading from './loading'

const TransitStop = (props) => {
  const [countdown, setCountdown] = useState(0)

  const {
    stop_id,
    stop_type,
    stop_name,
    isFavorite,
    arrivals,
    loadStopInfo,
    loadStopArrivals,
    updateFavoriteStops,
    isLoading
  } = props

  const increment = 1000
  const reloadTime = 30 * increment

  // load stop info & arrivals on mount
  useEffect(() => {
    loadStopInfo('transit', stop_id)
    loadStopArrivals(stop_id)
  }, [stop_id, loadStopInfo, loadStopArrivals])

  // reload arrivals every 30 seconds with countdown
  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown >= reloadTime) {
        loadStopArrivals(stop_id)
        setCountdown(0)
      } else {
        setCountdown(countdown + increment)
      }
    }, increment)

    // clear interval on unmount
    return () => clearInterval(interval)
  }, [countdown, reloadTime, stop_id, loadStopArrivals])

  const handleFavoriteClick = (e) => {
    e.preventDefault()
    updateFavoriteStops({
      stop_type: stop_type,
      stop_id: stop_id
    })
  }

  return (
    <div className='stop main-container content-width'>
      <progress
        id='countdown-bar'
        className={`main-container content-width ${isLoading ? 'clear' : ''}`}
        max={reloadTime}
        value={countdown}
      />
      <div className='stop-map-preview'>
        <MapPreview
          stop_lat={props.stop_lat}
          stop_lon={props.stop_lon}
          stop_id={props.stop_id}
          stop_type={props.stop_type}
        />
      </div>
      <div className='heading-bar'>
        <div className='stop-heading'>{stop_name}</div>
        <div className='favorite'>
          {
            // eslint-disable-next-line
            <a
              href=''
              className={`favorite ${isFavorite && 'gold'}`}
              onClick={handleFavoriteClick}
            >
              <i className='fa fa-star fa-lg' />
            </a>
          }
        </div>
      </div>
      <div className='arrivals'>
        {isLoading ? (
          <Loading />
        ) : arrivals && arrivals.length > 0 ? (
          arrivals.map((arrival) => (
            <ArrivalBar
            key={`${stop_id}-${arrival.trip_id}-${arrival.departure_time}`}
            {...arrival}
            />
          ))
        ) : (
          <div className={`arrival-bar pnd`}>
            <div className='arrival-info'>No Data</div>
          </div>
        )}
      </div>
      <Legend />
    </div>
  )
}

export default TransitStop
