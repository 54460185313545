import React from 'react'
import StopPreview from '../components/stop_preview'

const MapStopPreview = (props) => {
  const { stop_id, stop_type } = props
  const visible = stop_id && stop_type

  return (
    <div id='map-stop-preview' className={visible ? 'visible' : ''}>
      {visible ? (
        <StopPreview
          key={`${stop_type}-${stop_id}`}
          hideNoData={false}
          {...props}
        />
      ) : null}
    </div>
  )
}

export default MapStopPreview
