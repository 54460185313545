import React from 'react'
import { NavLink } from 'react-router-dom'

const More = () => {
  return (
    <div className='more main-container content-width'>
      <div className='heading-bar'>
        <div className='heading bottom-border'>More</div>
      </div>
      <ul className='link-list'>
        <li className='heading'>
          <NavLink to='/apps'>
            <span className='list-icon'>
              <i className='fa fa-mobile fa-5' aria-hidden='true' />
            </span>
            <span className='text'>Mobile Apps</span>
          </NavLink>
        </li>
        <li className='heading'>
          <NavLink to='/about'>
            <span className='list-icon'>
              <i className='fa fa-info-circle fa-4' aria-hidden='true' />
            </span>
            <span className='text'>About</span>
          </NavLink>
        </li>
        <li className='heading'>
          <a
            href='https://medium.com/@omgtransit'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='list-icon'>
              <i className='fa fa-rss fa-5' aria-hidden='true' />
            </span>
            <span className='text'>Blog</span>
          </a>
        </li>
        <li className='heading'>
          <a
            href='mailto:omgtransit@gmail.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='list-icon'>
              <i className='fa fa-envelope fa-5' aria-hidden='true' />
            </span>
            <span className='text'>Contact</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default More
